<div class="wrapper">
    <div id="content" class="content">
        <div class="container-fluid container-margin-component">
            <div class="row component">
                <div class="card mx-auto login-container">
                    <div class="card-body">
                        <div class="alert alert-danger" role="alert" *ngIf="loginError">
                            {{loginErrorMsg}}
                          </div>
                        <form [formGroup]="loginForm">
                            <input type="text"
                                formControlName="userName" 
                                class="form-control"
                                placeholder="Användarnamn"
                                required>
                            <br>
                            <input type="password"
                                formControlName="password" 
                                class="form-control"
                                placeholder="Lösenord"
                                required>
                            <br>
                            <div class="col text-center">
                                <button class="btn btn-dark"
                                    type="submit"
                                    (click)="login()">Logga in
                                </button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>