<div class="wrapper">    
    <loader display="{{showLoader}}"></loader>
    <div id="content" class="content">
        <div class="container-fluid container-margin-component">
            <div class="row component">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <div class="details-module" *ngFor="let camera of folders">
                        <div class="details-module-header" (click)="showCameraFolder(camera?.folderName)">
                            <h2 class="toggle-title">
                                <i class="fa chevron" 
                                    [ngClass]="{'fa-chevron-down': camera?.show, 'fa-chevron-right': !camera?.show}" 
                                    aria-hidden="true">
                                </i>&nbsp;{{ camera.folderName }}&nbsp;
                            </h2>
                        </div>
                        <div class="details-body" *ngIf="camera.show">
                            <div class="row">                                        
                                <div class="col-sm-12 col-md-12">
                                    <div class="details-module" *ngFor="let dateFolder of camera?.subFolderList">
                                        <div class="details-module-header" (click)="showCameraDate(camera?.folderName, dateFolder?.folderName)">
                                            <h2 class="toggle-title">
                                                <i class="fa chevron" 
                                                    [ngClass]="{'fa-chevron-down': dateFolder?.show, 'fa-chevron-right': !dateFolder?.show}" 
                                                    aria-hidden="true">
                                                </i>&nbsp;{{ dateFolder.folderName }}&nbsp;
                                            </h2>
                                        </div>
                                        <div class="details-body" *ngIf="dateFolder.show">
                                            <div class="row">                                        
                                                <div class="col-sm-12 col-md-12">
                                                    <ng-container *ngFor="let image of dateFolder.folderImagesList; let i = index">
                                                        <a href="{{image?.imageUrl}}" data-lightbox="roadtrip"><img [src]="image?.imageUrl" style="width:250px; margin-bottom: 1rem; margin-right: 1rem;"></a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
