import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth';
import { Globals } from '../globals';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {

  constructor(private http: HttpClient, private authService: AuthenticationService, private globals: Globals) { }

}
