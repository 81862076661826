import { Component } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HomeService } from './home.service';
import { AppService } from '../app.service';
import { environment } from '../../environments/environment';
import { Globals } from '../globals';

@Component({
  selector: 'app-home',
  providers: [HomeService, AppService],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  env: any;

  constructor(private http: HttpClient,
    private appService: AppService) { 
    this.env = environment;
  }

  /**
   * Set route on click
   * @param routerLink route name
  */
  setRoute(routerLink) {
    this.appService.setRoute(routerLink);  
  }

}
