import { Component } from '@angular/core';
import { FormGroup, Validators, MinLengthValidator, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
    loginForm: FormGroup;
    loginError: boolean = false;
    loginErrorMsg: string = '';
  

    constructor(
        private authService: AuthenticationService,
        private router: Router) {
        this.setDefault();
    }

    /**
     * Set defaults when page is loaded
     */
    setDefault(){
        this.loginForm = new FormGroup({
            userName: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
    }

    /**
     * log in a user using provided credentials
     */
    login(): void {
        if(this.loginForm.controls['userName'].valid && this.loginForm.controls['password'].valid){
            this.authService.login(this.loginForm.get('userName').value, this.loginForm.get('password').value)
                .subscribe(
                    response => {
                        this.authService.saveToken(response);
                        this.router.navigate(['home']);
                    },
                    error => this.setLoginError(error)
                );
        } else {
            this.setLoginError('Alla fält är inte ifyllda');
        }
    }

    /**
     * Sets the error text in the login card
     * @param error 
     */
    setLoginError(error){
        this.loginErrorMsg = error;
        this.loginError = true;
    }
}
