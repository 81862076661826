import { Component } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CameraService } from './camera.service';
import { AppService } from '../app.service';
import { Globals } from '../globals';

import { Lightbox, LightboxConfig, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription }  from 'rxjs';

@Component({
  selector: 'app-camera',
  providers: [CameraService, AppService],
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent {

  
  private _subscription: Subscription;
  folders: any;
  showLoader: Boolean = false;

  cameraAlbums = [];

  constructor(private http: HttpClient,
    private appService: AppService,
    private globals: Globals,
    private cameraService: CameraService,
    private lightbox: Lightbox,
    private lightboxEvent: LightboxEvent,
    private lightboxConfig: LightboxConfig){ 
      lightboxConfig.showImageNumberLabel = true;
      lightboxConfig.disableScrolling = true;
      this.setDefaults();
  }

  /**
  * set default data.
  */
  setDefaults(){
    this.getFolders();
  }


  /**
  * get folder structure from backend
  */
  getFolders(){
    this.cameraService.getFolders()
      .subscribe(
        data => this.setFolders(data),
        error => console.log(error)
      ); 
  }

  /**
  * Set the folder structure received from backend
  * @param folderData the folder structure with images
  */
  setFolders(folderData){
    this.folders = folderData;
  }

  /**
  * Show the subfolders of the camera that was clicked on
  * @param cameraName name of camera
  */
  showCameraFolder(cameraName){
    this.folders.forEach(cameraElement => {
      if(cameraElement.folderName === cameraName){
        cameraElement.show = !cameraElement.show
      }
    });
  }

  /**
  * Show images in a date folder
  * @param cameraName name of camera
  * @param dateFolderName date folder
  */
  showCameraDate(cameraName, dateFolderName){
    this.folders.forEach(cameraElement => {
      if(cameraElement.folderName === cameraName){
        cameraElement.subFolderList.forEach(dateElement => {
          if(dateElement.folderName === dateFolderName){
            dateElement.show = !dateElement.show
          }
        });
      }
    });
  }

  imageViewed(cameraFolder, dateFolder, imageName, imageStatus){
    if(imageStatus){
      this.folders.forEach(cameraElement => {
        if(cameraElement.folderName === cameraFolder){
          cameraElement.subFolderList.forEach(dateElement => {
            if(dateElement.folderName === dateFolder){
              dateElement.folderImagesList.forEach(imageElement => {
                if(imageElement.imageName === imageName){
                  imageElement.newImage = false;
                }
              });
            }
          });
        }
      });
    }
    this.checkIfNewExists(cameraFolder, dateFolder)
  }

  test(cameraName, dateFolder, arr, index){
    this.lightbox.open(arr, index);
    //call to set viewed
    this.imageViewed(cameraName, dateFolder, arr[index].imageName, arr[index].newImage)
    this._subscription = this.lightboxEvent.lightboxEvent$
      .subscribe(event => this._onReceivedEvent(event, arr, cameraName, dateFolder));
      
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.cameraAlbums, index);
  }
  
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  private _onReceivedEvent(event: any, arr, cameraName, dateFolder): void {
    // remember to unsubscribe the event when lightbox is closed
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      // event CLOSED is fired
      this.checkIfNewExists(cameraName, dateFolder)
      this._subscription.unsubscribe();
    }
   
    if (event.id === LIGHTBOX_EVENT.OPEN) {
      // event OPEN is fired
    }
   
    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      // event change page is fired
      //console.log('change event')
      //console.log(event.data); // -> image index that lightbox is switched to
      this.imageViewed(cameraName, dateFolder, arr[event.data].imageName, arr[event.data].newImage)
    }
  }

  checkIfNewExists(cameraFolder, dateFolder){
    let dateStatusArr = [];
    let imageStatusArr = [];
    
    this.folders.forEach(cameraElement => {
      if(cameraElement.folderName === cameraFolder){
        cameraElement.subFolderList.forEach(dateElement => {
          //get all date folder statuses
          dateStatusArr.push(dateElement.newImage)
          //get status for specific date folder
          if(dateElement.folderName === dateFolder){
            dateElement.folderImagesList.forEach(imageElement => {
              imageStatusArr.push(imageElement.newImage)
            });
          }
        });
      }
    });

    if(!dateStatusArr.some(status => status === true)){
      this.folders.forEach(cameraElement => {
        if(cameraElement.folderName === cameraFolder){
          cameraElement.newImage = false;
        }
      });
    }

    if(!imageStatusArr.some(status => status === true)){
      this.folders.forEach(cameraElement => {
        if(cameraElement.folderName === cameraFolder){
          cameraElement.subFolderList.forEach(dateElement => {
            if(dateElement.folderName === dateFolder){
              dateElement.newImage = false;
            }
          });
        }
      });
    }
  }
}
