import { Component, HostListener, SimpleChanges  } from '@angular/core';
import { AuthGuard, AuthenticationService } from '../../../auth/index';
import { AppService } from '../../../app.service';
import { Globals } from '../../../globals';
import { environment } from '../../../../environments/environment';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-header',
  providers: [AppService],
  templateUrl: './header.component.html',
  styleUrls: ['../../../app.component.css']
})
export class HeaderComponent {

  currentUser: any;
  showTimeoutModal: boolean = false;
  content: string;
  title: string;
  idle: number;
  timeout: number;
  ping: number;
  counter: number;
  timeoutConfig: any = [];
  env: any;

  @HostListener('document:mousemove', ['$event'])
    onMouseMove(e) {
      if (this.showTimeoutModal && this.authService.isUserAuthenticated()) {
        this.restart();            
        this.hideWarning();
        this.authService.getNewToken();            
      }
    }

  @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      if (this.showTimeoutModal && this.authService.isUserAuthenticated()) {
        this.restart();            
        this.hideWarning();            
        this.authService.getNewToken(); 
      }
    }
  
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchmove', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouchEvent(e){
    if (this.showTimeoutModal && this.authService.isUserAuthenticated()) {
      this.restart();            
      this.hideWarning();
      this.authService.getNewToken();            
    }
  }

  constructor(public authGuard: AuthGuard,
    private authService: AuthenticationService,
    private appService: AppService,
    private userIdle: UserIdleService,
    public globals: Globals) {
      this.idle = globals.idle;
      this.timeout = globals.timeout;
      this.ping = globals.ping;
      this.timeoutConfig = [this.idle, this.timeout, this.ping];

      this.env = environment;
    }
  

  ngOnInit() : void {
    this.authService.isAuthenticated();
    this.userIdle.setConfigValues(this.timeoutConfig);
    this.userIdle.startWatching();
    this.userIdle.ping$.subscribe(() => this.sendRefreshToken());
    this.userIdle.onTimerStart().subscribe(count => this.displayWarning(count));
  }

  /**
  * Display modal when timeout countdown starts
  */
  displayWarning(count) {
    this.counter = count;
    if (this.counter != null || this.counter != undefined) {
      if (this.authService.isUserAuthenticated()) {
        this.title = "Du kommer snart loggas ut på grund av inaktivitet.";
        this.showTimeoutModal = true;
        if (this.counter >= this.timeout) {
          this.hideWarning();
          this.stopTimer();
          this.logOut();
        }
      }
    }
  }

  /**
  * get the new token
  */
  sendRefreshToken() {
    this.authService.getNewToken(); 
  }

  /**
  * Hide timeout modal
  */
  hideWarning() {
      this.showTimeoutModal = false;
  }

  /**
  * Stop userIdle timer
  */
  stopTimer() {
    this.userIdle.stopTimer();
  }

  /**
  * Stop watching if user is idle
  */
  stopWatching() {
    this.userIdle.stopWatching();
  }

  /**
  * Start watching if user is idle
  */
  startWatching() {
    this.userIdle.startWatching();
  }

  /**
  * Re-start the idle timer
  */
  restart() {
    this.userIdle.resetTimer();
  }

  /**
   * Log out the user 
   */
  logOut() : void {
    this.authService.logout();
  }

  /**
   * Set new route
   * @param route new route
   */
  setRoute(route) {
    this.appService.setRoute(route);
  }

  staySignedIn(){
    this.restart();            
    this.hideWarning();
    this.authService.getNewToken();  
  }
}
