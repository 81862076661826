import { Component } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TestService } from './test.service';
import { AppService } from '../app.service';
import { Globals } from '../globals';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-test',
  providers: [TestService, AppService],
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent {

  name: any;
  role: any;
  userInfo: any = [];
  folders: any;
  images: any;
  cameraFolders: any;
  dateFolders: any;
  showLoader: Boolean = false;
  private _albums: any = [];

  constructor(private http: HttpClient,
    private appService: AppService,
    private globals: Globals,
    private testService: TestService,
    private sanitizer: DomSanitizer) { 

      this.setDefaults();
    }

    setDefaults(){
      this.getFolders();
    }

    /**
     * get the user name
     */
    getUserName(){
      this.testService.getUserName()
        .subscribe(
          data => console.log(data),
          error => console.log(error)
        );   
    }

    /**
     * set the user name
     * @param data user name data to set
     */
    setUsername(data){
      this.name = data;
    }

    /**
     * get the user role
     */
    getUserRole(){
      this.testService.getUserRole()
        .subscribe(
          data => console.log(data),
          error => console.log(error)
        );   
    }

    /**
     * set the user role
     * @param data user role data to set
     */
    setUserRole(data){
      this.role = data;
    }

    /**
     * get the user info
     */
    getUserInfo(){
      this.testService.getUserInfo()
        .subscribe(
          data => this.setUserInfo(data),
          error => console.log(error)
        );   
    }

    /**
     * set the user info
     * @param data user info data to set
     */
    setUserInfo(data){
      this.userInfo = data;
      this.globals.username = data.user_name;
      this.globals.name = data.name;
      this.globals.given_name = data.given_name;
      this.globals.family_name = data.family_name;
    }

    getFolders(){
      this.testService.getFolders()
        .subscribe(
          data => this.setFolders(data),
          error => console.log(error)
        ); 
    }
    setFolders(folderData){
      this.folders = folderData;
      console.log(this.folders)
    }
    setImages(data){
      this.images = data;
    }

    decodeBase64(image){
      let data = 'data:image/jpg;base64,'
      data += image;
      return this.sanitizer.bypassSecurityTrustResourceUrl(data);
    }

    getCameraFolders(){
      this.testService.getCameraFolders()
      .subscribe(
        data => this.setCameraFolders(data),
        error => console.log(error)
      ); 
    }
    setCameraFolders(folderData){
      this.cameraFolders = folderData;
    }

    getDateFolders(){
      this.testService.getDateFolders()
      .subscribe(
        data => this.setDateFolders(data),
        error => console.log(error)
      ); 
    }
    setDateFolders(folderData){
      this.dateFolders = folderData;
    }

    showCameraFolder(cameraName){
      this.folders.forEach(cameraElement => {
        if(cameraElement.folderName === cameraName){
          cameraElement.show = !cameraElement.show
        }
      });
    }

    showCameraDate(cameraName, dateFolderName){
      this.folders.forEach(cameraElement => {
        if(cameraElement.folderName === cameraName){
          cameraElement.subFolderList.forEach(dateElement => {
            if(dateElement.folderName === dateFolderName){
              dateElement.show = !dateElement.show
            }
          });
        }
      });
    }

}
