export class Item {
    constructor (
        public id: number,
        public name: string,
        public shortName: string,
        public routeName: string,
        public icon: string,
        public color: string,
        public selected: boolean,
        public active: boolean,
        public description: string,
        public roleType: string) { }    
}