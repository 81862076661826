import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AppService {

    constructor(private router: Router){}

    /**
     * Set route
     * @param routeLink route name
     */
    setRoute(routeLink): void {        
        this.router.navigate([routeLink]);
    }

}