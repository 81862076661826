import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})

export class LoaderComponent implements OnChanges {

    @Input()
    display: Boolean;

    constructor() { 
        this.display = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.display = changes.display.currentValue == "true";        
    }

}