<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a *ngIf="globals.username == undefined || globals.username.length == 0" class="navbar-brand pointer" (click)="setRoute('')">ostbulle.net</a>
    <a *ngIf="globals.username != undefined && globals.username.length > 0" class="navbar-brand pointer" (click)="setRoute('')">TrailCamView</a>
    <button *ngIf="authGuard.canAccess()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="authGuard.canAccess()">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link pointer" (click)="setRoute('home')">Start <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" *ngFor="let component of env.components">
          <a class="nav-link pointer" (click)="setRoute(component.routeName)">{{component.name}}</a>
        </li>
      </ul>
      <ul class="navbar-nav navbar-right">
        <li style="color:white; padding-top: 0.1em; padding-right: 0.1em; font-size: 18px;" *ngIf="globals.given_name != undefined && globals.given_name.length > 0 && globals.family_name != undefined && globals.family_name.length > 0">
          {{globals.given_name}}&nbsp;{{globals.family_name}}&nbsp;&nbsp;
        </li>
        <li style="color:white; padding-top: 0.1em; padding-right: 0.1em; font-size: 18px;" *ngIf="globals.given_name == undefined || globals.given_name.length === 0 || globals.family_name == undefined || globals.family_name.length === 0">
            {{globals.username}}&nbsp;&nbsp;
        </li>  
        <li><a href="javascript:;" (click)="logOut()" class="fa fa-sign-out logout-icon" aria-hidden="true" style="margin-top: 0.2rem;" data-toggle="tooltip" data-placement="top" title="Logga ut">&nbsp;</a></li>                    
    </ul>
    </div>
</nav>
<div class="modal-backdrop-black fade in" [style.display]="showTimeoutModal ? 'block' : 'none'" style="z-index: 10020 !important"></div>
<div class="modal" id="timeoutModal" [style.display]="showTimeoutModal ? 'block' : 'none'" style="z-index: 10040" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><i class="fa fa-exclamation-circle text-warning"  style="font-size: 1em;" aria-hidden="true"></i>&nbsp;{{ title }}</h5>
            </div>
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar bg-dark" role="progressbar" style="width: 75%" [attr.aria-valuenow]="counter" aria-valuemin="0" [attr.aria-valuemax]="timeout" [style.width]="(counter/timeout)*100 + '%'"></div>
                </div>
                <div style="margin-top: 1rem;">
                  <button class="btn btn-dark"
                    type="submit"
                    (click)="staySignedIn()"
                    [ngClass]="">
                      <span class="fa fa-check"></span>&nbsp;Förbli inloggad
                  </button>

                </div>
            </div>
        </div>
    </div>
</div>