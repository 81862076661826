import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth';
import { Globals } from '../globals';
import { Observable } from 'rxjs';

@Injectable()
export class TestService {


  constructor(private http: HttpClient, private authService: AuthenticationService, private globals: Globals) { }

  /**
   * get username from backend
   */
  getUserName() : Observable<any> {
    this.authService.isAuthenticated();
    const url = this.authService.getApiUrl() + '/api/v1/test/username';
    let requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));
    return this.http.get(url, {headers: requestHeaders, responseType: 'text'});
  }

  /**
   * get user role from backend
   */
  getUserRole() : Observable<any> {
    this.authService.isAuthenticated();
    const url = this.authService.getApiUrl() + '/api/v1/test/roles';
    let requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));
    return this.http.get(url, {headers: requestHeaders, responseType: 'json'});
  }

  /**
   * get user info from backend
   */
  getUserInfo() : Observable<any> {
    this.authService.isAuthenticated();
    const url = this.authService.getApiUrl() + '/api/v1/test/userinfo';
    let requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));
    return this.http.get(url, {headers: requestHeaders, responseType: 'json'});
  }

  getFolders() : Observable<any> {
    this.authService.isAuthenticated();
    const url = this.authService.getApiUrl() + '/api/v1/test/images';
    let requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));
    return this.http.get(url, {headers: requestHeaders, responseType: 'json'});
  }
  getCameraFolders() : Observable<any> {
    this.authService.isAuthenticated();
    const url = this.authService.getApiUrl() + '/api/v1/test/cameraFolders';
    let requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));
    return this.http.get(url, {headers: requestHeaders, responseType: 'json'});
  }
  getDateFolders() : Observable<any> {
    this.authService.isAuthenticated();
    const url = this.authService.getApiUrl() + '/api/v1/test/cameraFolders';
    let requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));
    return this.http.get(url, {headers: requestHeaders, responseType: 'json'});
  }
}