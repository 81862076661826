<div class="wrapper">    
    <loader display="{{showLoader}}"></loader>
    <div id="content" class="content">
        <div class="container-fluid container-margin-component">
            <div class="row component">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <div class="details-module" *ngFor="let camera of folders">
                        <div class="details-module-header" (click)="showCameraFolder(camera?.folderName)">
                            <h2 class="toggle-title">
                                <i class="fa chevron" 
                                    [ngClass]="{'fa-chevron-down': camera?.show, 'fa-chevron-right': !camera?.show}" 
                                    aria-hidden="true">
                                </i>&nbsp;<span class="pointer">{{ camera?.folderName }}</span>&nbsp;<span *ngIf="camera?.newImage" class="badge badge-success pa-badge">Ny bild</span>
                            </h2>
                        </div>
                        <div class="details-body" *ngIf="camera?.show">
                            <div class="row">                                        
                                <div class="col-sm-12 col-md-12">
                                    <div class="details-module" *ngFor="let dateFolder of camera?.subFolderList">
                                        <div class="details-module-header" (click)="showCameraDate(camera?.folderName, dateFolder?.folderName)">
                                            <h2 class="toggle-title">
                                                <i class="fa chevron" 
                                                    [ngClass]="{'fa-chevron-down': dateFolder?.show, 'fa-chevron-right': !dateFolder?.show}" 
                                                    aria-hidden="true">
                                                </i>&nbsp;<span class="pointer">{{ dateFolder?.folderName }}</span>&nbsp;  <span *ngIf="dateFolder?.newImage" class="badge badge-success pa-badge">Ny bild</span>
                                            </h2>
                                        </div>
                                        <div class="details-body-image" *ngIf="dateFolder?.show">
                                            <div class="row">                                        
                                                <div class="col-xs" *ngFor="let image of dateFolder.folderImagesList; let i = index">
                                                    <!-- lightbox -->
                                                    <!--<a href="{{image?.imageUrl}}" [attr.data-lightbox]="camera?.folderName + ' ' + dateFolder?.folderName" >
                                                        <span *ngIf="image?.newImage" class="blla">Ny bild</span>
                                                        <img [src]="image?.imageUrl" style="width:250px; margin-bottom: 1rem; margin-right: 1rem;">
                                                    </a> -->
                                                    <!-- ngx-lightbox -->
                                                    <span *ngIf="image?.newImage" class="blla">Ny bild</span>
                                                    <img class="pointer" [src]="image?.imageUrl" style="width:250px; margin-bottom: 1rem; margin-right: 1rem;" (click)="test(camera?.folderName, dateFolder?.folderName, dateFolder?.folderImagesList, i)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
