<div class="container-fluid container-margin">
    <div class="row">
        <div class="col-sm-5 col-md-3 component-card" *ngFor="let component of env.components" (click)="setRoute(component.routeName)">     
            <div class="component-card-inner">                 
                <p class="text-center"><i class="fa {{component.icon}} component-icon-style {{component.color}}" aria-hidden="true"></i></p>                      
                <div class="caption text-center">
                    <h3>{{component.name}}</h3>
                    <p style="min-height: 60px;">{{component.description}}</p>
                    <p>&nbsp;</p>                       
                </div>   
            </div>       
        </div>
    </div>   
</div>