import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UserIdleModule } from 'angular-user-idle';
import { Globals } from './globals';
import { HttpClientXsrfModule, HttpInterceptor, HttpXsrfTokenExtractor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from 'ngx-bootstrap/modal';

import { HeaderComponent, LoaderComponent } from './shared';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { TestComponent } from './test/test.component';
import { AuthGuard, AuthenticationService } from './auth/index';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CameraComponent } from './camera/camera.component';

import { LightboxModule } from 'ngx-lightbox';

const rootRouting: ModuleWithProviders<AppModule> = RouterModule.forRoot([], { useHash: false });

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'X-XSRF-TOKEN';
    let token = this.tokenExtractor.getToken() as string;
    
    if (token !== null && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }
    return next.handle(req).pipe(catchError(err => {
        if(err.status === 401) {
            this.router.navigate(['/login']);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
        }
    ));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    HomeComponent,
    TestComponent,
    CameraComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-Token',
    }),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 540 (9 minutes), `timeout` is 30 (30 sek)
    // and `ping` is 240 (4 minutes).
    UserIdleModule.forRoot({idle: 60, timeout: 30, ping: 20}),
    HttpClientModule,
    ModalModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LightboxModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    AuthGuard,
    AuthenticationService,
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
