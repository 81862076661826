import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  username: string = 'undefined';
  userrole: string = 'undefined';
  fullname: string = 'undefined';
  name: string = 'undefined'
  given_name: string = 'undefined';
  family_name: string = 'undefined';

  // Timeout
  // Idle-bugg: Make sure these values match with those in app.module.ts
  idle: number = 540;
  timeout: number = 30;
  ping: number = 240;
}