// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Item } from "./item";
import itemSettings from "./itemSettings.json";

var components = new Array<Item>();

for (let i = 0; i < itemSettings.component.length; i++) {
  components.push(new Item(i+1, 
    itemSettings.component[i].fullName, 
    itemSettings.component[i].shortName, 
    itemSettings.component[i].routeName, 
    itemSettings.component[i].icon, 
    itemSettings.component[i].color, 
    itemSettings.component[i].selected, 
    itemSettings.component[i].active, 
    itemSettings.component[i].description, 
    itemSettings.component[i].roleType));
}


export const environment = {
  production: false,
  components
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
