import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Cookie } from 'ng2-cookies';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import defaultApiUrl from "../../apiUrl.json";

@Injectable()
export class AuthenticationService  {

    private username: string;
    private userrole: string;
    private fullname: string;
    private givenname: string;
    private familyname: string;
    private apiUrl: string;

    private expireDate: any;

    constructor(private router: Router, private http: HttpClient, private globals: Globals) {
        this.username = globals.username;
        this.fullname = globals.fullname;
        this.givenname = globals.given_name;
        this.familyname = globals.family_name;
        this.userrole = globals.userrole;
        this.setApiUrl();
    }

    setApiUrl(){
        if(window.location.origin === "http://localhost:8085"){
            this.apiUrl = defaultApiUrl.url.apiUrl
        } else {
            this.apiUrl = window.location.origin
        }
    }

    getApiUrl(){
        return this.apiUrl;
    }

    jwtHelper = new JwtHelperService();

    /**
     * Send login request to backend
     * @param userName user name from login form
     * @param password password from login form
     */
    login(userName: string, password: string) : Observable<any>{

        const url = this.getApiUrl() + '/api/v1/login';
        const requestHeaders = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');

        let requestParams = new HttpParams()
        .set('username', userName)
        .set('password', password);

        return this.http.post(url, requestParams, {headers: requestHeaders, responseType: 'json' });
    }

    /**
     * Get new token from backend using refresh token
     */
    getNewToken() {
        if(this.isUserAuthenticated()){
            this.isAuthenticated();

            let requestParams = new HttpParams()
                .set('refresh_token', Cookie.get('refreshToken'));

            const requestHeaders = new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
                .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));

            this.http.post(this.getApiUrl() + '/api/v1/login/refresh', requestParams, {headers: requestHeaders, responseType: 'json' }).subscribe(
                data => {
                    this.saveToken(data);               
                    return true;
                },
                error => {
                    this.logout();
                    return false;
                });
        }
    }

    /**
    * Getter for the refreshToken
    */
    /*public getRefreshToken() {
        return Cookie.get('refreshToken');
    }*/

    /**
    * Getter to fetch expire date for refresh token from the cookie
    */
    /*public getExpireDate() {
        return Cookie.get('expireDate');
    }*/

    /**
     * Save token recieved from backend to cookie
     * @param token JWT token from backend
     */
    saveToken(token) {
        this.expireDate = null;
        if(token.expires_in != null) {
            this.expireDate = new Date().getTime() + (1000 * token.expires_in); // 600000 = 10 min, 60000
        } else {
            this.expireDate = new Date().getTime() + (1000 * 20);
        }

        Cookie.set('accessToken', token.access_token, new Date(this.expireDate));
        Cookie.set('refreshToken', token.refresh_token, new Date(this.expireDate));
        Cookie.set('expireDate', this.expireDate);
        var user = {
            username: token.user_name,
            fullName: token.given_name + ' ' + token.family_name,
            given_name: token.given_name,
            family_name: token.family_name
        };
        
        Cookie.set('currentUser', JSON.stringify(user), new Date(this.expireDate));
        this.username = Cookie.get('currentUser').split('"')[3];
        this.fullname = Cookie.get('currentUser').split('"')[7];
        this.familyname = Cookie.get('currentUser').split('"')[15];
        this.givenname = Cookie.get('currentUser').split('"')[11];
        this.globals.username = this.username;
        this.globals.fullname = this.fullname;
        this.globals.given_name = this.givenname;
        this.globals.family_name = this.familyname;
    }

    /**
     * Logout user and delete cookies
     */
    logout() {
        this.deleteCookies();
        this.router.navigate(['/login']);
    }

    /**
    * Delete the cookies and clear globals
    */
    deleteCookies() {
        Cookie.delete('accessToken');
        Cookie.delete('refreshToken');
        Cookie.delete('expireDate');
        Cookie.delete('currentUser');
        this.globals.username = "";
        this.globals.fullname = "";
        this.globals.given_name = "";
        this.globals.family_name = "";
        this.globals.userrole = "";
    }

    /**
     * Check if the access token is expired
     */
    isAuthenticated(): void {
        const token = Cookie.get('accessToken');
        if (token.length > 0 && this.jwtHelper.isTokenExpired(token)) {
            this.logout();
        }
        this.username = Cookie.get('currentUser').split('"')[3];
        this.fullname = Cookie.get('currentUser').split('"')[7];
        this.familyname = Cookie.get('currentUser').split('"')[15];
        this.givenname = Cookie.get('currentUser').split('"')[11];
        this.globals.username = this.username;
        this.globals.fullname = this.fullname;
        this.globals.given_name = "";
        this.globals.family_name = "";
    }

    /**
     * Check if the user is authenticated
     */
    isUserAuthenticated() {
        const token = Cookie.get('accessToken');
        if (token.length > 0 && !this.jwtHelper.isTokenExpired(token)) {
            return true;
        } else {
            return false;
        }
    }
}
