import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './auth.service';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,  public authService: AuthenticationService, public http: HttpClient) { }

    /**
    * Check on each request to ensure our refresh and access token are valid and updated
    * If the user leaves the application to rest for too long they will automatically have their session invalidated
    */
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (Cookie.get('accessToken') && Cookie.get('expireDate') != undefined) {
            let inProgress = false;
            let dateNow = new Date().getTime();
            if(((+Cookie.get('expireDate') - dateNow) < 120000) && !inProgress) {
                inProgress = true;
                let requestParams = new HttpParams()
                    .set('refresh_token', Cookie.get('refreshToken'));
    
                const requestHeaders = new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
                    .set('Authorization', 'Bearer ' + Cookie.get('accessToken'));

                await this.http.post(this.authService.getApiUrl() + '/api/v1/login/refresh', requestParams, {headers: requestHeaders, responseType: 'json' }).subscribe(
                    data => {
                        this.authService.saveToken(data);
                        inProgress = false;
                        this.router.navigate([state.url]);
                        return true;
                    },
                    error => {
                        this.authService.logout();
                        return false;
                    });
            } else {
                return true;
            }
        } else {
            this.authService.logout();
            return false;
        }
    }

    /**
     * check if a user is logged in
     */
    canAccess() {
      if (Cookie.get('accessToken')) {
          return true;
      }
      return false;
    }
}